import React from 'react';

export interface ModalProps {
  title: string;
  onClose: () => any;
  children: any;
}

const Modal = (props: ModalProps) => {
  return (
    <div id="modal" className="p-modal">
      <div
        className="p-modal__dialog"
        style={{
          display: 'grid',
          gridTemplateRows: 'auto auto auto'
        }}
      >
        <header className="p-modal__header">
          <h2 className="p-modal__title" id="modal-title">
            {props.title}
          </h2>
          <button className="p-modal__close" onClick={props.onClose} />
        </header>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
