import React from 'react';

import { Redirect } from 'react-router';

export interface SiteSelectProps {
  sites: Site[];
}

const SiteSelect = (props: SiteSelectProps) => {
  const [site, setSite] = React.useState<number | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setSite(parseInt(event.target.value, 10));

  if (site) {
    return <Redirect to={`/annotation/${site}`} />;
  }

  return (
    <div>
      <label htmlFor="site">Site</label>
      <select id="site" onChange={handleChange}>
        <option disabled={true} selected={true}>
          Select a site
        </option>
        {Object.values(props.sites).map(s => (
          <option key={s.id} value={s.id}>
            {s.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SiteSelect;
