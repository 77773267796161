import React from 'react';

import axios from 'axios';

import CreateArea from './CreateArea';
import CreateEvent from './CreateEvent';
import Map from './Map';

export interface SiteProps {
  selectedSite: Site;
  position?: any;
  accessToken: string;
  eventTypes: EventType[];
}

interface SiteState {
  isFetching: boolean;
  areas: Area[];
  error?: Error;
  selectedArea?: Area;
  draftedArea?: any; // perimeter
}

class SiteAnnotate extends React.Component<SiteProps, SiteState> {
  constructor(props: SiteProps) {
    super(props);
    this.state = {
      areas: [],
      isFetching: false
    };

    this.selectArea = this.selectArea.bind(this);
    this.onAreaDraw = this.onAreaDraw.bind(this);
    this.onNewAreaSubmit = this.onNewAreaSubmit.bind(this);
    this.resetEvent = this.resetEvent.bind(this);
    this.resetArea = this.resetArea.bind(this);
  }

  public fetchAreas() {
    this.setState({ isFetching: true });

    const params = {
      site: this.props.selectedSite.id
    };

    // TODO(vmatt): use axios instance configured with accesstoken
    const headers = {
      Authorization: 'Bearer ' + this.props.accessToken
    };

    axios
      .get('/api/areas', { params, headers })
      .then(
        res => this.setState({ areas: res.data, isFetching: false }),
        err => this.setState({ error: err, isFetching: false })
      );
  }

  public resetEvent() {
    this.selectArea(undefined);
  }

  public resetArea() {
    this.setState({ draftedArea: undefined });
  }

  public selectArea(area: Area | undefined) {
    this.setState({ selectedArea: area });
  }

  public onAreaDraw(perimeter: any) {
    this.setState({ draftedArea: perimeter });
  }

  public onNewAreaSubmit(name: string) {
    const area = {
      name,
      site: this.props.selectedSite && this.props.selectedSite.id,
      perimeter: this.state.draftedArea.geometry
    };

    const headers = {
      Authorization: 'Bearer ' + this.props.accessToken
    };

    axios
      .post('/api/areas/', area, { headers })
      .then(res => {
        this.setState({ areas: [...this.state.areas, res.data] });
        this.resetArea();
      })
      .catch(err => console.dir(JSON.parse(JSON.stringify(err))));
  }

  public componentDidMount(): void {
    this.fetchAreas();
  }

  public render() {
    return (
      <>
        <Map
          areas={this.state.areas}
          site={this.props.selectedSite}
          selectArea={this.selectArea}
          onAreaDraw={this.onAreaDraw}
        />
        {this.state.draftedArea && (
          <CreateArea
            selectedSite={this.props.selectedSite}
            onSubmitArea={this.onNewAreaSubmit}
            onClose={this.resetArea}
          />
        )}
        {this.state.selectedArea && (
          <CreateEvent
            selectedSite={this.props.selectedSite}
            selectedArea={this.state.selectedArea}
            eventTypes={this.props.eventTypes}
            position={this.props.position}
            accessToken={this.props.accessToken}
            onClose={this.resetEvent}
          />
        )}
      </>
    );
  }
}

export default SiteAnnotate;
