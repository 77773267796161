import React from 'react';

import axios from 'axios';
import _ from 'lodash';

import { Route } from 'react-router';

import SiteAnnotate from '../components/SiteAnnotate';
import SiteSelect from '../components/SiteSelect';

import './Annotation.css';
import EventList from '../components/EventList';

interface AnnotationState {
  isFetching: boolean;
  sites: Site[];
  eventTypes: EventType[];
  position?: Position;
  selectedSite?: Site;
  error?: string;
}

export interface AnnotationProps {
  accessToken: string;
}

class Annotation extends React.Component<AnnotationProps, AnnotationState> {
  private watchId!: number;

  constructor(props: AnnotationProps) {
    super(props);
    this.state = {
      isFetching: false,
      sites: [],
      eventTypes: []
    };

    this.onPositionUpdate = _.throttle(this.onPositionUpdate, 10000).bind(this);
  }

  public fetchSites() {
    this.setState({ isFetching: true });

    const headers = {
      Authorization: 'Bearer ' + this.props.accessToken
    };

    axios
      .get('/api/sites', { headers })
      .then(
        res => this.setState({ sites: res.data, isFetching: false }),
        err => this.setState({ error: err, isFetching: false })
      );
  }

  public fetchEventTypes() {
    this.setState({ isFetching: true });

    const headers = {
      Authorization: 'Bearer ' + this.props.accessToken
    };

    axios
      .get('/api/event-types', { headers })
      .then(
        res => this.setState({ eventTypes: res.data, isFetching: false }),
        err => this.setState({ error: err, isFetching: false })
      );
  }

  public onPositionUpdate(position: Position) {
    this.setState({ position });
  }

  public watchLocation() {
    this.watchId = navigator.geolocation.watchPosition(
      this.onPositionUpdate,
      this.handlePositionError
    );
  }

  public handlePositionError = (error: PositionError) => {
    let errorMessage: string;

    if (error.code !== error.PERMISSION_DENIED) {
      errorMessage =
        'This app needs geolocation to work. Please reload this page and enable geolocation.';
    } else {
      errorMessage = 'Geolocation error.';
    }

    this.setState({ error: errorMessage });
  };

  public componentDidMount(): void {
    this.watchLocation();
    this.fetchSites();
    this.fetchEventTypes();
  }

  public componentWillUnmount(): void {
    navigator.geolocation.clearWatch(this.watchId);
  }

  public render() {
    return (
      <>
        <Route
          exact={true}
          path="/annotation"
          render={props => <SiteSelect sites={this.state.sites} />}
        />

        <Route
          exact={true}
          path="/annotation/:id"
          render={props => {
            const id = parseInt(props.match.params.id, 10);
            const site = this.state.sites.find(s => s.id === id);
            return (
              <SiteAnnotate
                accessToken={this.props.accessToken}
                selectedSite={site as Site}
                eventTypes={this.state.eventTypes}
                position={this.state.position}
              />
            );
          }}
        />

        <Route
          exact={true}
          path="/annotation/:id/events"
          render={props => {
            const id = parseInt(props.match.params.id, 10);
            const site = this.state.sites.find(s => s.id === id);
            return (
              <EventList
                selectedSite={site as Site}
                accessToken={this.props.accessToken}
                eventTypes={this.state.eventTypes}
              />
            );
          }}
        />
      </>
    );
  }
}
export default Annotation;
