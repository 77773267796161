import React from 'react';
import { Redirect } from 'react-router';

import './Auth.css';

import axios from '../utils/axios';

import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikActions,
  FormikProps
} from 'formik';

export interface AuthProps {
  accessToken: string | undefined;
  setAccessToken: (token: string) => void;
}

interface FormValues {
  username: string;
  password: string;
}

const Auth = (props: AuthProps) => {
  const fetchToken = (username: string, password: string) => {
    axios
      .post('api/token/', { username, password })
      .then(res => {
        props.setAccessToken(res.data.access);
      })
      .catch(err => console.dir(err));
  };

  const validate = (values: FormValues) => {
    const errors: any = {};
    if (values.username.length === 0) {
      errors.username = 'Empty login not allowed.';
    }
    if (values.password.length === 0) {
      errors.password = 'Empty password not allowed.';
    }
  };

  const handleSubmit = (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    actions
      .validateForm(values)
      .then(() => fetchToken(values.username, values.password))
      .finally(() => actions.setSubmitting(false));
  };

  if (props.accessToken) {
    return <Redirect to="/annotation" />;
  }

  return (
    <div className="auth-container">
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formikBag: FormikProps<FormValues>) => (
          <Form className="u-align--center">
            <label htmlFor="username">Username</label>
            <Field name="username" type="text" autoFocus={true} />
            <ErrorMessage name="username" />
            <label htmlFor="password">Password</label>
            <Field name="password" type="password" />
            <ErrorMessage name="password" />
            <button type="submit">Login</button>
          </Form>
        )}
      </Formik>

      <svg
        className="svg-wrapper"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 350 250"
      >
        <defs>
          <clipPath id="clip-path">
            <path d="M1046.91,414.44h-3.54a23.4,23.4,0,0,0-22.12,15.93l-16.85,46-17.88-41.66a19.32,19.32,0,0,0-18.34-13.11h-.28a19.32,19.32,0,0,0-18.31,13.28L932.29,476.5l-17-45.85a23.4,23.4,0,0,0-22.2-15.86l-20.46,0L919.6,546l12.09,0a23.4,23.4,0,0,0,21.86-15.21l14.74-39.47,15,39.42a23.4,23.4,0,0,0,21.93,15.09l12.35,0L1063.6,414.4l-16.69,0Z" />
          </clipPath>
        </defs>
        <g transform="translate(-800, -350)">
          <g clipPath="url(#clip-path)">
            <circle className="circle-1" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-2" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-3" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-4" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-5" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-6" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-7" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-8" cx="968.38" cy="494.64" r="125" />
            <circle className="circle-9" cx="968.38" cy="494.64" r="125" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Auth;
