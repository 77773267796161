import React from 'react';

import axios from 'axios';
import _ from 'lodash';

export interface EventListProps {
  selectedSite: Site;
  accessToken: string;
  eventTypes: EventType[];
}

function EventList(props: EventListProps) {
  const [isFetching, setIsFetching] = React.useState(true);
  const [error, setError] = React.useState('');

  const [events, setEvents] = React.useState<Event[]>([]);
  const [areas, setAreas] = React.useState<Area[]>([]);
  const [updatedEvent, setUpdatedEvent] = React.useState<Event | null>(null);

  const eventTypesById = _.keyBy(props.eventTypes, eT => eT.id);
  const areaById = _.keyBy(areas, a => a.id);

  React.useEffect(() => {
    const params = {
      site: props.selectedSite.id
    };

    const headers = {
      Authorization: 'Bearer ' + props.accessToken
    };

    const p1 = axios
      .get('/api/events', { params, headers })
      .then(res => setEvents(_.sortBy(res.data, e => -e.id)))
      .catch(err => setError(err));

    const p2 = axios
      .get('/api/areas', { params, headers })
      .then(res => setAreas(res.data))
      .catch(err => setError(err));

    Promise.all([p1, p2]).finally(() => setIsFetching(false));
  }, [props.selectedSite.id]);

  React.useEffect(() => {
    if (!updatedEvent) {
      return;
    }

    const headers = {
      Authorization: 'Bearer ' + props.accessToken
    };

    axios
      .put(`/api/events/${updatedEvent.id}/`, { ...updatedEvent }, { headers })
      .catch(err => {
        setError(err);
      })
      .finally(() => setUpdatedEvent(null));
  }, [updatedEvent]);

  if (isFetching) {
    return <i className="p-icon--spinner u-animation--spin" />;
  }

  if (error) {
    return <div className="p-card">Something went wrong.</div>;
  }

  const handleClick = (ev: any) => {
    const id = parseInt(ev.target.dataset.id, 10);
    const event = _.find(events, e => e.id === id) as Event;
    event.valid = !event.valid;
    setUpdatedEvent(event);
  };

  return (
    <div className="p-card" style={{ alignSelf: 'stretch' }}>
      <h3 className="p-card__title">Events in {props.selectedSite.name}</h3>
      <div className="p-card__content">
        <table className="p-table--sortable" role="grid">
          <thead>
            <tr role="row">
              <th role="columnheader" scope="col" id="t-timestamp">
                Timestamp
              </th>
              <th role="columnheader" scope="col" id="t-area">
                Area
              </th>
              <th role="columnheader" scope="col" id="t-type">
                Type
              </th>
              <th role="columnheader" scope="col" id="t-type">
                Validity
              </th>
            </tr>
          </thead>
          <tbody>
            {events.map(r => (
              <tr key={r.id} role="row">
                <td role="rowheader">
                  <small>{new Date(r.timestamp).toLocaleString()}</small>
                </td>
                {[areaById[r.area].name, eventTypesById[r.eventType].name].map(
                  d => (
                    <td role="gridcell" key={d}>
                      <small>{d}</small>
                    </td>
                  )
                )}
                <td role="gridcell">
                  <button
                    className="p-button--neutral"
                    type="button"
                    data-id={r.id}
                    onClick={handleClick}
                    style={
                      r.valid
                        ? { backgroundColor: 'hsl(120, 100%, 80%)' }
                        : { backgroundColor: 'hsl(0, 100%, 80%)' }
                    }
                  >
                    {r.valid ? (
                      <i className="p-icon--success" data-id={r.id} />
                    ) : (
                      <i className="p-icon--error" data-id={r.id} />
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EventList;
