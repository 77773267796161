import React from 'react';

import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikActions,
  FormikProps
} from 'formik';

import Modal from './Modal';

export interface CreateAreaProps {
  selectedSite: Site;
  onSubmitArea: (name: string) => void;
  onClose: any;
}

interface FormValues {
  name: string;
}

const CreateArea = (props: CreateAreaProps) => {
  const handleSubmit = (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    actions.validateForm(values);
    actions.setSubmitting(false);
    props.onSubmitArea(values.name);
  };

  const modalTitle = 'New Area';

  const [error, setError] = React.useState<any>(null);

  const validate = (values: FormValues) => {
    const errors: any = {};
    if (!/.{4,}/.test(values.name)) {
      errors.name = 'Name must be at least 4 characters long.';
    }
    if (!/\w[\w\d]*/.test(values.name)) {
      errors.name =
        'Name must start with a letter and contains only letters and digits.';
    }
    return errors;
  };

  if (error) {
    return (
      <Modal onClose={props.onClose} title={modalTitle}>
        {JSON.stringify(error, null, 2)}
      </Modal>
    );
  }

  return (
    <Modal title={modalTitle} onClose={props.onClose}>
      <div>
        <p>
          You created a new area in site <b>{props.selectedSite.name}</b>
        </p>
      </div>

      <Formik
        initialValues={{
          name: ''
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formikBag: FormikProps<FormValues>) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="name">Name the new area:</label>
            <Field name="name" type="text" autoFocus={true} />
            <ErrorMessage name="name" />
            <button type="submit">Create area</button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateArea;
