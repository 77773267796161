import React from 'react';

import L from 'leaflet';
import 'leaflet-draw';

import { schemeCategory10 } from 'd3-scale-chromatic';

export interface MapProps {
  site: Site;
  areas: Area[];
  selectArea: (area: Area | undefined) => void;
  onAreaDraw: (perimeter: any) => void;
}

// TODO(vmatt): stop rerender of the map with refs

// Leaflet uses [y,x], which is not practical to use.
// So we work with [x,y] and invert when needed.
const yx = ([x, y]: [number, number]): [number, number] => [y, x];

class Map extends React.Component<MapProps> {
  private mapContainer!: L.Map;
  private areasLayer!: L.FeatureGroup;

  public componentDidMount(): void {
    this.show();
  }

  public componentDidUpdate(prevProps: Readonly<MapProps>): void {
    if (prevProps.areas.length !== this.props.areas.length) {
      this.updateAreas();
    }
  }

  public render() {
    return (
      <div className="map-wrapper">
        <div id="map" />
      </div>
    );
  }

  private initAreas() {
    if (!this.areasLayer) {
      // LeafletEvent is improperly typed. So we use any.
      this.areasLayer = L.featureGroup()
        .on('click', (event: any) => {
          this.props.selectArea(event.sourceTarget.__area);
        })
        .addTo(this.mapContainer);

      this.mapContainer.addControl(
        new L.Control.Draw({
          draw: {
            polyline: false,
            marker: false,
            circlemarker: false,
            rectangle: false,
            circle: false
          },
          edit: { featureGroup: this.areasLayer, edit: false, remove: false }
        })
      );

      const areaEvents = {
        [L.Draw.Event.CREATED]: (event: any) => {
          this.props.onAreaDraw(event.layer.toGeoJSON());
        }
      };

      this.mapContainer.on(areaEvents);
    }
  }

  private showAreas() {
    this.props.areas.forEach((area, index) => {
      const poly = L.polygon(
        // TODO(vmatt): use geojson
        area.perimeter.coordinates[0].map((c: any) =>
          yx(c as [number, number])
        ),
        { color: schemeCategory10[index], fillOpacity: 0.7 }
      ).addTo(this.areasLayer);

      // In order to share handler with group,
      // we set a ref to the area in each associated polygon instance.
      (poly as any).__area = area;
    });
  }

  private clearAreas() {
    if (this.areasLayer) {
      this.areasLayer.clearLayers();
    }
  }

  private updateAreas() {
    this.initAreas();
    this.clearAreas();
    this.showAreas();
  }

  private show() {
    const mapContainer = (this.mapContainer = L.map('map', {
      crs: L.CRS.Simple
    }));

    const defaultBounds = L.latLngBounds([[0, 0], [1000, 1000]]);

    const imageOverlay = L.imageOverlay(this.props.site.map, defaultBounds, {
      opacity: 0.7
    })
      .addTo(mapContainer)
      .on('load', (e: L.LeafletEvent) => {
        const { naturalHeight, naturalWidth } = e.target._image;
        const coeff = 800 / Math.max(naturalWidth, naturalHeight);
        const bounds = L.latLngBounds([
          [0, 0],
          [naturalHeight * coeff, naturalWidth * coeff]
        ]);

        imageOverlay.setBounds(bounds);
        mapContainer.fitBounds(bounds);
      });

    this.mapContainer.fitBounds(defaultBounds);

    this.updateAreas();
  }
}

export default Map;
