import React from 'react';

import './App.scss';
import { ReactComponent as WavelyLogo } from './wavely.svg';

import { NavLink, Redirect, Route, Switch } from 'react-router-dom';

import Annotation from './pages/Annotation';
import Auth from './pages/Auth';

interface AppState {
  accessToken?: string;
}

class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  public setAccessToken = (token: string) => {
    this.setState({ accessToken: token });
  };

  public render() {
    return (
      <div className="app">
        <header id="navigation" className="p-navigation">
          <div className="p-navigation__banner">
            <div className="p-navigation__logo">
              <a className="p-navigation__link">
                <WavelyLogo className="p-navigation__image" />
              </a>
            </div>
          </div>
          <Route
            path="/annotation/:id"
            render={props => (
              <nav className="custom-p-navigation__nav align-content-right">
                <ul className="p-navigation__links">
                  <Route
                    exact={true}
                    path="/annotation/:id"
                    render={props => (
                      <li className="p-navigation__link">
                        <NavLink
                          to={`/annotation/${props.match.params.id}/events`}
                        >
                          Events
                        </NavLink>
                      </li>
                    )}
                  />
                  <li className="p-navigation__link">
                    <Switch>
                      <Route
                        path="/annotation/:id/events"
                        render={props => (
                          <NavLink to={`/annotation/${props.match.params.id}`}>
                            Go back
                          </NavLink>
                        )}
                      />
                      <Route
                        path="/annotation/:id"
                        render={props => (
                          <NavLink to="/annotation">Go back</NavLink>
                        )}
                      />
                    </Switch>
                  </li>
                </ul>
              </nav>
            )}
          />
        </header>

        <main>
          <Switch>
            <Route
              exact={true}
              path="/"
              render={props =>
                this.state.accessToken ? (
                  <Redirect to="/annotation" />
                ) : (
                  <Auth
                    accessToken={this.state.accessToken}
                    setAccessToken={this.setAccessToken}
                    {...props}
                  />
                )
              }
            />
            <Route
              path="/annotation"
              render={props =>
                this.state.accessToken ? (
                  <Annotation accessToken={this.state.accessToken} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Redirect to="/" />
          </Switch>
        </main>
      </div>
    );
  }
}
export default App;
