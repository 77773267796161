import React from 'react';

import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikActions,
  FormikProps
} from 'formik';

import axios from '../utils/axios';

import Modal from './Modal';

export interface CreateEventProps {
  selectedSite: Site;
  selectedArea: Area;
  eventTypes: EventType[];
  position?: Position;
  accessToken: string;
  onClose: any;
}

interface FormValues {
  eventType: number;
}

interface RadioButtonProps {
  id: number;
  label: string;
}

const RadioButton = ({
  field,
  form,
  id,
  label
}: FieldProps & RadioButtonProps) => {
  const handleChange = () => form.setFieldValue('eventType', id);
  return (
    <div>
      <input
        type="radio"
        name="eventType"
        id={id.toString()}
        checked={id === field.value}
        onChange={handleChange}
        onBlur={field.onBlur}
      />
      <label htmlFor={id.toString()}>{label}</label>
    </div>
  );
};

const CreateEvent = (props: CreateEventProps) => {
  const handleSubmit = (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    actions.validateForm(values);
    actions.setSubmitting(false);
    postEvent(values);
  };

  const [error, setError] = React.useState<any>(null);
  const [success, setSuccess] = React.useState(false);

  let timestamp;
  React.useEffect(() => {
    timestamp = new Date();
  });

  const postEvent = (values: FormValues) => {
    if (!props.position) {
      return;
    }

    const headers = {
      Authorization: 'Bearer ' + props.accessToken
    };

    axios
      .post(
        '/api/events/',
        {
          eventType: values.eventType,
          area: props.selectedArea.id,
          timestamp: new Date(),
          position: {
            type: 'Point',
            coordinates: [
              props.position.coords.longitude,
              props.position.coords.latitude
            ]
          }
        },
        { headers }
      )
      .then(props.onClose)
      .catch(err => {
        if (err.response) {
          const { status, statusText, data } = err.response;
          setError({ status, statusText, data });
        } else {
          setError({
            status: 500,
            statusText: 'Internal Server Error',
            data: null
          });
        }
      });
  };

  const validate = (values: FormValues) => {
    const errors: any = {};
    if (values.eventType < 0) {
      errors.eventType = 'Please select an event type.';
    }
    return errors;
  };

  if (!props.position) {
    return (
      <Modal onClose={props.onClose} title="New Event">
        {
          'If you have allowed geolocation, please wait one more second. If not, please reload this page and allow geolocation.'
        }
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal onClose={props.onClose} title="New Event">
        {JSON.stringify(error, null, 2)}
      </Modal>
    );
  }

  return (
    <Modal onClose={props.onClose} title="New Event">
      <div>
        <p>
          You are reporting a new event in area <b>{props.selectedArea.name}</b>{' '}
          on site <b>{props.selectedSite.name}</b>
        </p>
      </div>
      <Formik
        initialValues={{
          eventType: -1
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formikBag: FormikProps<FormValues>) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              justifySelf: 'stretch'
            }}
          >
            <div>
              <label htmlFor="eventType">Event Type</label>
              {props.eventTypes.map(eventType => (
                <Field
                  component={RadioButton}
                  name="eventType"
                  key={eventType.id}
                  id={eventType.id}
                  label={eventType.name}
                  autofocus={true}
                />
              ))}
              <ErrorMessage name="eventType" />
            </div>
            <button type="submit">Send event</button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateEvent;
